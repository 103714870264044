import { Button } from '@nike/eds'
import { UnitDetailsPanel } from 'components/left-detail-panel'
import { MainPanel } from 'components/main-panel'
import { NumberInput } from 'components/number-input/NumberInput'
import { useEffect, useState } from 'react'
import { type Unit, type GradeConfig, GradeConfigs } from 'types'
import { type ApiHandlerContext } from 'utils/useApiHandler'

import './Grading.scss'
import './transitions.css'
import { GradeButtons } from './GradeButtons'
import { MainGradeButtons } from './MainGradeButtons'

interface GradingProps {
  unitData: Unit
  rasnChannel: string | undefined
  handleReturn: () => void
  updateUnitGradeContext: ApiHandlerContext<Unit>
  useHash: boolean
  useBgrade: boolean
}

interface GradeReason {
  code: string
  reason: string
}

export const Grading = ({ unitData, rasnChannel, handleReturn, updateUnitGradeContext, useHash, useBgrade }: GradingProps) => {
  const [grade, setGrade] = useState<string>('')
  const [gradeReason, setGradeReason] = useState<GradeReason>({ code: '', reason: '' })
  const [quantity, setQuantity] = useState<number>(1)
  const [clicked, setClicked] = useState(false)
  const [displayMainGrades, setDisplayMainGrades] = useState(true)

  // If the unit is already graded the user came from a next screen
  // Displaying the grade and reason which was previously selected
  useEffect(() => {
    const config: GradeConfig | undefined = GradeConfigs[unitData.grade]
    if (config) {
      setGrade(config.key)
      if (config.needsReason) {
        setGradeReason({ code: unitData.isegCode, reason: unitData.gradeReason })
        setDisplayMainGrades(false)
      }
    }
  }, [])

  // Prevents previously selected grade from triggering the grading action, required for returning from a next screen
  useEffect(() => {
    if (!clicked) return
    const config = GradeConfigs[grade]
    if (grade !== '' && (!config.needsReason || gradeReason.code !== '')) {
      performGrade(config)
    }
  }, [clicked, grade, gradeReason])

  const performGrade = (config: GradeConfig) => {
    const updatedUnit = {
      ...unitData,
      grade: config.key,
      qualityCode: config.qualityCode,
      isegCode: config.needsReason ? gradeReason.code : '000',
      gradeReason: config.needsReason ? gradeReason.reason : '',
      quantity,
      status: 'GRADED'
    }
    if (!updateUnitGradeContext.isLoading) {
      updateUnitGradeContext.handleApiCall(updatedUnit).then()
    }
  }

  const handleGradeClick = (newGrade: string) => {
    setGrade(newGrade)
    if (GradeConfigs[newGrade].needsReason) {
      setDisplayMainGrades(false)
    } else {
      setGradeReason({ code: '', reason: '' })
      setClicked(true)
    }
  }

  const handleGradeReasonClick = (reason: { code: string, reason: string }) => {
    setGradeReason(reason)
    setClicked(true)
  }

  const handleBackToMainGrades = () => {
    setDisplayMainGrades(true)
    setGradeReason({ code: '', reason: '' })
  }

  const leftPanelContent = <UnitDetailsPanel unit={unitData} showImage={true}/>

  const checkIfHashable = () => {
    return useHash && unitData.hashable
  }

  const mainPanelContent = <div className='flex flex-col flex-grow justify-end w-3/5'>
    {displayMainGrades
      ? (
          <div key={'main-grades'} className={'animated fadeInBottom mb-20'}>
            <MainGradeButtons activeGrade={grade} handleGradeChange={handleGradeClick} hashable={checkIfHashable()} bGradable={useBgrade} />
         </div>)
      : (<div key={grade} className={'animated fadeInBottom'}>
            <GradeButtons gradeReason={gradeReason} setGradeReason={handleGradeReasonClick} grade={GradeConfigs[grade].display}/>
         </div>)
    }
    <div className='flex justify-between w-100 mt-20'>
      <Button className='text-4xl' onClick={() => {
        !displayMainGrades && GradeConfigs[grade].needsReason ? handleBackToMainGrades() : handleReturn()
      }} variant='secondary'>
        Return
      </Button>
      { rasnChannel === 'NON_DIGITAL' && <NumberInput value={quantity} setValue={setQuantity} minimum={1} /> }
    </div>
  </div>

  return <MainPanel leftPanelContent={leftPanelContent} mainPanelContent={mainPanelContent} />
}
