import { Skeleton } from '@nike/eds'
import { type Unit } from 'types'
import { GradeConfigs } from 'types'

import './UnitDetailsPanel.scss'

interface UnitDetailsPanelProps {
  unit: Unit
  showImage: boolean
}

export const UnitDetailsPanel = ({ unit, showImage }: UnitDetailsPanelProps) => {
  const gradeConfig = GradeConfigs[unit.grade]
  const isGraded = () => {
    return !!unit.grade
  }

  return (
    <div className={'flex-col'}>
      <div className="grid grid-cols-[3fr,8fr] gap-y-1 text-3xl">
        <div className="font-bold text-5xl">SKU</div><div className={'text-5xl pb-2'}>{unit.productCode}-{unit.sizeCode}</div>
        <div className="font-bold">Description</div><div>{unit.styleDescription}</div>
        <div className="font-bold">COO</div><div>{unit.countryOfOriginFull} ({unit.countryOfOrigin})</div>
        <div className="font-bold">Silhouette</div><div>{unit.silhouette}</div>
        <div className="font-bold">UPC</div><div>{unit.upc}</div>
        {isGraded() &&
          <>
            {/* If reason code is required, format as `{grade}   {reason}`, else as `Grade   {first char of grade}` */}
            <div className="font-bold">{gradeConfig.needsReason ? gradeConfig.display : 'Grade'}</div>
            <div>{gradeConfig.needsReason ? unit.gradeReason : gradeConfig.display.charAt(0)}</div>
          </>
        }
      </div>
      {showImage && (!unit.imageUrl
        ? <div className="flex justify-center mt-6">
          <Skeleton height={700} width={700} />
        </div>
        : <div className={'image-container'}>
            <img id="materialImage" src={unit.imageUrl} alt="Material image" />
          </div>)
      }
    </div>
  )
}
