import { BGradeReason, BGradeReasonSEC, CGradeReason, CGradeReasonSEC } from 'types'

const isSec = process.env.REACT_APP_STACK === 'sec'
export const getGradeReasons = (grade: string) => {
  return isSec
    ? grade === 'B-Grade' ? BGradeReasonSEC : CGradeReasonSEC
    : grade === 'B-Grade' ? BGradeReason : CGradeReason
}
export const getCGradeQualityCode = () => {
  return isSec ? '03' : '02'
}
