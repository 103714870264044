import { useFetchCountryOfOriginsQuery } from 'api/country'
import React, { useState, useEffect, useRef, type Dispatch, type SetStateAction } from 'react'
import './CountrySelect.scss'
import { type CountryOfOrigin } from 'types'

interface CountrySelectProps {
  isOpen: boolean
  setIsOpen: Dispatch<SetStateAction<boolean>>
  selected?: CountryOfOrigin | undefined
  onChange: Dispatch<SetStateAction<CountryOfOrigin | undefined>>
  placeholder?: string
  className?: string
  confirm: (country: CountryOfOrigin) => void
}

export const CountrySelect = ({ isOpen, setIsOpen, selected, onChange, placeholder = 'Select a country', className = '', confirm }: CountrySelectProps) => {
  const [searchTerm, setSearchTerm] = useState('')
  const [filteredCountries, setFilteredCountries] = useState<CountryOfOrigin[]>([])
  const [focusedIndex, setFocusedIndex] = useState(0)
  const wrapperRef = useRef<HTMLDivElement>(null)
  const searchInputRef = useRef<HTMLInputElement>(null)
  const scrollIntoViewRef = useRef<HTMLDivElement>(null)
  const { data: countries, isLoading, isError } = useFetchCountryOfOriginsQuery()

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target as Node)) {
        setIsOpen(false)
      }
    }
    document.addEventListener('mousedown', handleClickOutside)
    return () => { document.removeEventListener('mousedown', handleClickOutside) }
  }, [isOpen])
  // Small timeout to ensure the input exists in the DOM
  useEffect(() => {
    if (isOpen) {
      setTimeout(() => {
        searchInputRef.current?.focus()
      }, 0)
    }
  }, [isOpen])

  // Filter countries based on search term, pushes more precise matches to the top
  // If 1 letter is entered, filter by first letter of country code
  // If 2 entered, filter by exact country code match
  // Then, display all other matches
  useEffect(() => {
    if (!countries) return
    if (!searchTerm) {
      setFilteredCountries(countries)
      return
    }

    const term = searchTerm.toUpperCase()
    let countryCodeMatches: CountryOfOrigin[] = []
    if (term.length === 1) {
      countryCodeMatches = countries.filter(
        country => country.countryCode[0] === term
      )
    } else if (term.length === 2) {
      countryCodeMatches = countries.filter(
        country => country.countryCode === term
      )
    }
    const otherMatches: CountryOfOrigin[] = countries
      .filter(country => !countryCodeMatches.includes(country))
      .filter(
        country =>
          (country.countryCode.includes(term) ||
                country.fullName.toUpperCase().includes(term)) &&
            country.countryCode !== term
      )

    setFilteredCountries([...countryCodeMatches, ...otherMatches])
  }, [searchTerm, countries])

  const handleSelect = (country: CountryOfOrigin) => {
    onChange(country)
    setIsOpen(false)
    setSearchTerm('')
    confirm(country)
  }

  // beautification & UX:
  // scroll into view when arrow keys are pressed
  useEffect(() => {
    if (scrollIntoViewRef.current) {
      scrollIntoViewRef.current.scrollIntoView({
        // behavior: 'smooth'
        block: 'nearest'
      })
    }
  }, [focusedIndex])

  useEffect(() => {
    if (!isOpen) {
      setFocusedIndex(0)
    }
  }, [isOpen])

  if (!countries && isLoading) {
    return <div>Loading...</div>
  }
  if (!countries || isError) {
    return <div>Error fetching countries</div>
  }

  // Enter key to select country, arrow keys to navigate
  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    switch (e.key) {
      case 'ArrowDown':
        e.preventDefault()
        setFocusedIndex(prev =>
          prev < filteredCountries.length - 1 ? prev + 1 : 0 // wrap around
        )
        break
      case 'ArrowUp':
        e.preventDefault()
        setFocusedIndex(prev => prev > 0 ? prev - 1 : filteredCountries.length - 1) // wrap around
        break
      case 'Enter':
        handleSelect(filteredCountries[focusedIndex >= 0 ? focusedIndex : 0])
        break
      case 'Escape':
        setIsOpen(false)
        break
    }
  }

  return (
      <div ref={wrapperRef} className={`country-select ${className}`}>
        <div
            className={'country-select__trigger'}
            onClick={() => {
              setIsOpen(!isOpen)
            }}
        >
          {selected
            ? (
                  <span className={'country-select__trigger--has-value'}>{`${selected.countryCode} - ${selected.fullName}`}</span>
              )
            : (
                  <span className={'country-select__trigger--placeholder'}>{placeholder}</span>
              )}
        </div>

        {isOpen && filteredCountries && (
            <div className="country-select__dropdown">
              <input
                  type="text"
                  className="country-select__search-input"
                  value={searchTerm}
                  onChange={(e) => {
                    setSearchTerm(e.target.value)
                    setFocusedIndex(0)
                  }}
                  onKeyDown={handleKeyDown}
                  placeholder="Type to search..."
                  ref={searchInputRef}
              />
              <div className="country-select__options-container">
                {filteredCountries.map((country, index) => (
                    <div
                        key={country.countryCode}
                        className={`country-select__option ${
                            index === focusedIndex ? 'country-select__option--focused' : ''
                        }`}
                        onClick={() => {
                          handleSelect(country)
                        }}
                        onMouseEnter={() => {
                          setFocusedIndex(index)
                        }}
                        ref={index === focusedIndex ? scrollIntoViewRef : null}
                    >
                      {`${country.countryCode} - ${country.fullName}`}
                    </div>
                ))}
                {filteredCountries.length === 0 && (
                    <div className="country-select__no-results">
                      No countries found
                    </div>
                )}
              </div>
            </div>
        )}
      </div>
  )
}
