import { Icon, TextField } from '@nike/eds'
import { NumPad } from 'components/num-pad'
import React, { useEffect, useState } from 'react'

interface UpcEntryProps {
  upcBarcode: string
  error: string
  setError: (error: string) => void
  onUpcChange: (value: string) => void
  onConfirm: () => void
}
const UPC_MAX_LENGTH = 19

export const UpcEntry = ({
  upcBarcode,
  error,
  setError,
  onUpcChange,
  onConfirm
}: UpcEntryProps) => {
  const [showNumPad, setShowNumPad] = useState(false)
  const enterKeyHandler = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      onConfirm()
    }
  }

  useEffect(() => {
    if (upcBarcode.length > UPC_MAX_LENGTH) {
      setError('UPC is too long')
    } else {
      setError('')
    }
  }, [upcBarcode])
  return (
    <>
      <TextField
          id="upcBarcode"
          label="Please enter the UPC:"
          placeholder="Barcode"
          value={upcBarcode}
          onChange={e => { onUpcChange(e.currentTarget.value.trim()) }}
          hasErrors={error !== ''}
          errorMessage={error}
          onKeyUp={enterKeyHandler}
          beforeSlot={<Icon name="Scan"/>}
          autoFocus
      />
      <NumPad
          text={upcBarcode}
          setText={onUpcChange}
          showNumPad={showNumPad}
          setShowNumPad={setShowNumPad}
          confirm={onConfirm}
          className='absolute w-[900px] mb-5'
      />
    </>
  )
}
