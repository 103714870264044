
import {
  type CountryOfOrigin
} from 'types'

import { mainSplitApi } from './redux/mainSplitApi'

export const countryApi = mainSplitApi.injectEndpoints({
  endpoints: (builder) => ({
    fetchCountryOfOrigins: builder.query<CountryOfOrigin[], void>({
      query: () => {
        return {
          url: '/countries',
          method: 'GET'
        }
      }
    })
  }),
  overrideExisting: false
})

export const { useFetchCountryOfOriginsQuery } = countryApi
