import { TrinityButton } from 'components/trinity-button'
import { LabelType } from 'types'
import { type ApiHandlerContext } from 'utils/useApiHandler'

interface ActionButtonsProps {
  isManualInput: boolean
  onSwapScreens: () => void
  showBlindIlpn: boolean
  printLabelContext: ApiHandlerContext<any>
}

export const ActionButtons = ({
  isManualInput,
  onSwapScreens,
  showBlindIlpn,
  printLabelContext
}: ActionButtonsProps) => (
    <div className='absolute top-0 right-0'>
        <TrinityButton
            onClick={onSwapScreens}
            className={'mr-3'}
        >
            {isManualInput ? 'Scan UPC' : 'Manual SKU'}
        </TrinityButton>
        {showBlindIlpn && (
            <TrinityButton
                loading={printLabelContext.isLoading}
                onClick={() => { printLabelContext.handleApiCall(LabelType.BlindILPN) }}
            >
                Print lpn label
            </TrinityButton>
        )}
    </div>
)
